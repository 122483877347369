<template>
  <div class="myProfile">
    <div class="profile-title">My Profile</div>
    <div class="profile-cont">
      <a-form ref="formInfomation" :model="information" layout="vertical">
        <a-card title="User Information">
          <a-row :gutter="[20, 20]">
            <a-col :span="8">
              <a-form-item field="userId" label="User Id" :rules="[
                { required: true, message: 'User id is required' },
                {
                  match: /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/,
                  message: 'Please enter correct email format.'
                }
              ]">
                <a-input v-model="information.userId" disabled />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item field="firstName" label="First Name" :rules="[
                { required: true, message: 'First name is required' },
                { minLength: 2, maxLength: 35, message: 'Please Enter 2-35 characters.' }
              ]">
                <a-input v-model="information.firstName" placeholder="Required 2-35 characters" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item field="lastname" label="Last Name" :rules="[
                { required: true, message: 'Last name is required' },
                { minLength: 2, maxLength: 35, message: 'Please Enter 2-35 characters.' }
              ]">
                <a-input v-model="information.lastname" placeholder="Required 2-35 characters" />
              </a-form-item>
            </a-col>
            <a-col :span="9">
              <a-form-item field="phone" label="Phone Number" :rules="[
                { required: true, message: 'Phone is required' },
                { minLength: 10, maxLength: 15, message: 'Required 10-15 characters' }
              ]">
                <a-input v-model="information.phone" placeholder="Required 10-15 characters" />
              </a-form-item>
            </a-col>
            <a-col :span="3">
              <a-form-item field="extension" label="Extension"
                :rules="[{ minLength: 1, maxLength: 6, message: 'Must be 1-6 characters' }]">
                <a-input v-model="information.extension" placeholder="Optional" />
              </a-form-item>
            </a-col>
          </a-row>
        </a-card>
        <a-card title="Company" style="margin-top:24px;">
          <a-row :gutter="[20, 20]">
            <a-col :span="12">
              <a-form-item field="companyName" label="Company Name" :rules="[
                { maxLength: 35, minLength: 2, message: 'Must be 2-35 characters.' }
              ]">
                <a-input v-model="information.companyName" placeholder="Required 2-35 characters" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item field="companyType" label="Company Type">
                <a-radio-group v-model="information.companyType" type="button">
                  <a-radio :value="i + 1" v-for="val, k, i in company" :key="k">{{ val }}</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
          </a-row>
        </a-card>
        <div class="operate-btn">
          <a-space size="large">
            <a-button type="text" shape="round" @click="$router.push('/resetpwd')">Change Password?</a-button>
            <a-button type="primary" shape="round" @click="submit">Save Change</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import local from '@/utils/local'
// 引入api中的接口
import { userInfo, updateInfo } from '@/api/account.js'
import { Notification } from '@arco-design/web-vue'
const company = JSON.parse(local.get('configInfo')).company_type
const information = reactive({
  userId: '',
  phone: '',
  extension: '',
  firstName: '',
  lastname: '',
  companyName: '',
  companyType: 1
})
// 获取用户信息
const userData = reactive({ val: {} })
const getUserInfo = async () => {
  const msg = await userInfo({})
  if (msg.code === 0) {
    userData.val = msg.data
    information.userId = msg.data.email
    information.phone = msg.data.phone
    information.extension = msg.data.phone_extension
    information.firstName = msg.data.first_name
    information.lastname = msg.data.last_name
    information.companyName = msg.data.company_name
    information.companyType = msg.data.company_type
    local.set('userInfo', JSON.stringify(msg.data))
  }
}
getUserInfo()
const formInfomation = ref(null)
const submit = () => {
  formInfomation.value.validate(async (value) => {
    if (!value) {
      const msg = await updateInfo({
        type: 5,
        first_name: information.firstName,
        last_name: information.lastname,
        phone: information.phone,
        phone_extension: information.extension,
        company_name: information.companyName,
        company_type: information.companyType
      })
      if (msg.code === 0) {
        Notification.success({
          title: 'Success',
          content: 'Change saved.'
        })
      } else {
        Notification.error({
          title: 'Error',
          content: msg.message
        })
      }
    }
  })
}
</script>

<style lang="less" scoped>
.profile-title {
  font-weight: 500;
  font-size: 20px;
  color: var(--color-text-1);
  margin: 18px 24px 18px 48px;
}

.profile-cont {
  margin: 0 300px;
}

.operate-btn {
  margin-top: 24px;
  text-align: right;
}
</style>
